<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="posts"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/posts/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        
                                    </div>
                                    
                                    
                                </div>
                            </template>

                            <template v-slot:item.Create_time="{ item }">
                                {{ new Date(item.Create_time * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.Thumbnail="{ item }">
                                <img :src="item.Thumbnail" style="width: 50px; height: 50px" />
                            </template>
                            <template v-slot:item.Parent_cate="{ item }">
                                {{ item.Parent_cate ? item.Parent_cate.Name : "" }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/posts/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Tin tức'
    },
    data() {
        return {
            loading: false,
            options: {},
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", sortable: false, value: 'Thumbnail'},
                { text: "Tên", sortable: false, value: 'Name'},
                { text: "Ordinal", sortable: false, value: 'Ordinal'},
                { text: "Thời gian tạo", sortable: false, value: 'Create_time'},
                { text: "Actions", value: 'actions', sortable: false },
            ]
        };
    },
    watch: {
        
    },
    computed: {
        posts() {
            return this.$store.getters['posts/get_posts'];
        },
    },
    components: {
        
    },
    methods: {
        get_posts() {
            var self    = this;
            self.loading = true;

            this.$store.dispatch('posts/get_all').then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        edit_item(item) {
            console.log("edit item:", item);
            this.$router.push(`posts/update/${item.id}`).catch(() => { });
        },
        confirm_delete(item) {
            console.log("delete item:", item);
            var self = this;
            Swal.fire({
                title: `Xoá bài viết ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('posts/delete_post', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_posts();
            }).catch(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Tin tức", route: "index" } ]);
        this.get_posts();
    }
};
</script>
